import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';

const serverPreloadImagesPath =
  'https://carbucomstatic-5141.kxcdn.com/mazout_com/banner/CARBU_mazout_';
const imagesObj = {
  homePreloadImagesWinter: 'hiver',
  homePreloadImagesSpring: '2',
  homePreloadImagesSummer: '2',
  homePreloadImagesAutumn: 'automne',
  homePreloadImagesDefault: '2',
};
function getPreloadImagesImage(img) {
  var date_obj = new Date();
  var current_month = (date_obj.getMonth() + 1) % 12;
  let bannerImage = img.homePreloadImagesDefault;
  if (current_month >= 0 && current_month <= 3) {
    bannerImage = img.homePreloadImagesWinter;
  } else if (current_month >= 4 && current_month <= 8) {
    bannerImage = img.homePreloadImagesSummer;
  } else if (current_month >= 9 && current_month <= 11) {
    bannerImage = img.homePreloadImagesAutumn;
  } else {
    bannerImage = img.homePreloadImagesDefault;
  }
  return bannerImage;
}

const PreloadImages = ({ lang }) => {
  const [preloadedImages, setPreloadedImages] = useState([]);

  const bannerImagePaths = {
    xs: 'xs',
    sm: 'sm',
    lg: 'lg',
    xl: 'xl',
    xxl: 'xxl',
    default: 'xs',
  };

  const getPreloadImagesImagePath = (size) => {
    // Obtenir le nom de l'image de la bannière en fonction de la saison actuelle
    const bannerImage = getPreloadImagesImage(imagesObj);
    // Retourner le chemin complet de l'image en fonction de la taille spécifiée
    return `${serverPreloadImagesPath}${bannerImage}_${bannerImagePaths[size]}.webp`;
  };

  // Récupérer tous les chemins d'images de la banniere pour les tailles differentes
  // et les mapper vers leur URL complete en fonction de la saison actuelle
  useEffect(() => {
    const preloadImages = () => {
      Object.values(bannerImagePaths).forEach((size) => {
        const img = new Image();
        img.src = getPreloadImagesImagePath(size);
      });
    };

    // Check if the browser supports the 'preload' resource hint
    const supportsPreload = 'as' in document.createElement('link');
    if (supportsPreload) {
      // If supported, use the HTML preload for better browser optimization
      const tempPreloadedImages = Object.values(bannerImagePaths).map((size) => ({
        src: getPreloadImagesImagePath(size),
        srcset: `${getPreloadImagesImagePath('xs')} 400w,
                  ${getPreloadImagesImagePath('sm')} 768w,
                  ${getPreloadImagesImagePath('lg')} 1200w,
                  ${getPreloadImagesImagePath('xl')} 1400w,
                  ${getPreloadImagesImagePath('xxl')} 2000w`,
      }));
      setPreloadedImages(tempPreloadedImages);

      return () => {
        // Cleanup: Remove event listeners if necessary (not applicable in this case)
      };
    } else {
      // If not supported, use the JavaScript-based preloading as a fallback
      preloadImages();
    }
  }, []);

  return (
    <>
      <Helmet>
        {/* Pr charger les images de banniere pour toutes les tailles d'ecran
         (XS, SM, LG, XL, XXL) et les mapper vers leur URL compl te en fonction
         de la saison actuelle. Le navigateur chargera ainsi les images en
         arriere-plan lors de la navigation sur la page d'accueil. */}
        {/* {preloadedImages.map((image) => (
          <link
            rel='preload'
            as='image'
            key={image.src}
            href={image.src}
            srcSet={image.srcset}
            fetchPriority='high'
          />
        ))} */}
      </Helmet>
    </>
  );
};

export default PreloadImages;
